/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Stack, Button } from "@mui/material";
import { updateuserdata, viewByidUser } from "../../Api/User";
import Paymentgateway from "../Paymentgateway";

const Charges = () => {
  var id = sessionStorage.getItem("edituser");
  const [pgcharge, setpgcharge] = useState("");
  const [usercharge, setusercharge] = useState("");

  const submitbtn = async () => {
    var updatedata = await updateuserdata({
      id: id,
      charge: pgcharge,
      payout_charge: usercharge,
    });
    if (updatedata === "Updated Successfully") {
      toast.success("User Updated Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.replace("/user");
      }, 2000);
    }
  };
  useEffect(() => {
    getuserdata();
  }, [id]);
  const getuserdata = async () => {
    var userdata = await viewByidUser({ id: id });
    if (userdata.length !== 0) {
      setpgcharge(userdata[0].charge);
      setusercharge(userdata[0].payout_charge);
    }
  };
  return (
    <div>
      <div className="flex justify-between mt-5">
        <h1 className="text-start font-bold text-xl">Charges Update</h1>
      </div>
      <div className="py-5">
        <Stack spacing={0.6} direction={"column"}>
          <label className="text-left font-semibold">Pg Charge</label>
          <input
            type="text"
            size="medium"
            placeholder="Pg Charge"
            name="pgchrage"
            className="w-96 border border-gray-600 p-2 rounded mb-5"
            onChange={(e) => {
              setpgcharge(e.target.value);
            }}
            style={{ marginTop: "10px" }}
            defaultValue={pgcharge}
          />
          <div className="mt-5">
            <Stack spacing={2}>
              <Button
                variant="contained"
                size="small"
                className="w-24 items-start px-5"
                onClick={submitbtn}
                style={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Stack>
          </div>
        </Stack>
      </div>
      <Paymentgateway />
      {/* <div className="flex justify-between mt-5">
        <h1 className="text-start font-bold text-xl">Payout Update</h1>
      </div> */}

      {/* <div className="py-5">
        <Stack spacing={0.6} direction={"column"}>
          <label className="text-left font-semibold">Admin Charge</label>
          <input
            type="text"
            size="medium"
            placeholder="Pg Charge"
            name="pgchrage"
            className="w-96 border border-gray-600 p-2 rounded mb-5"
            onChange={(e) => {
              setadmincharge(e.target.value);
            }}
            style={{ marginTop: "10px" }}
            defaultValue={admincharge}
          />
          <label className="text-left font-semibold">User Charge</label>
          <input
            type="text"
            size="medium"
            placeholder="Pg Charge"
            name="pgchrage"
            className="w-96 border border-gray-600 p-2 rounded mb-5"
            onChange={(e) => {
              setusercharge(e.target.value);
            }}
            style={{ marginTop: "10px" }}
            defaultValue={usercharge}
          />
          <div className="mt-5">
            <Stack spacing={2}>
              <Button
                variant="contained"
                size="small"
                className="w-24 items-start px-5"
                onClick={submitbtn}
                style={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Stack>
          </div>
        </Stack>
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default Charges;
