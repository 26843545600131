/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Dropdown from "./Dropdown";
import { Alluserdata, updateuserdata } from "../Api/User";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { CreteWalletdebit } from "../Api/walletdebit";

const WalletDebit = () => {
  const [userdata, setuserdata] = useState([]);
  const [oldamount, setoldamount] = useState(0);
  const [id, setid] = useState(null);
  const [amount, setamount] = useState(0);
  const [clicked, setclicked] = useState(false);

  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var userdata = await Alluserdata();
    setuserdata(userdata);
  };
  const changeuser = async (e) => {
    if (e !== "null") {
      var checkuser = await userdata.filter((data) => {
        return data.id == e;
      });
      if (checkuser.length !== 0) {
        setid(checkuser[0].id);
        setoldamount(checkuser[0].balance);
      }
    } else {
      toast.error("Please Select User...", {
        autoClose: 2000,
        transition: Slide,
      });
      setid(null);
      setoldamount(0);
    }
  };
  const transferbtn = async () => {
    if (oldamount == 0) {
      toast.error("PG Balance is Zero...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (amount == 0) {
      toast.error("Please Enter Amount...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      if (Number(oldamount) >= Number(amount)) {
        var data = {
          balance: Number(oldamount) - Number(amount),
          id: id,
        };
        var updatedate = {
          date: moment().format("DD-MM-YYYY, h:mm:ss a"),
          amount: amount,
          opening_bal: oldamount,
          closing_bal: Number(oldamount) - Number(amount),
          user_id: id,
        };
        var update = await updateuserdata(data);
        if (update === "Updated Successfully") {
          await CreteWalletdebit(updatedate);
          toast.success("Amount Transfered successfully...", {
            autoClose: 2000,
            transition: Slide,
          });
          setoldamount(0);
          setamount(0);
          setid(null);
          setclicked(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        setclicked(false);
        toast.error("The amount must be less than the Balance....", {
          autoClose: 2000,
          transition: Slide,
        });
        setoldamount(0);
        setamount(0);
        setid(null);
      }
    }
  };
  return (
    <div className="text-black font-bold">
      <h2 className="text-left py-1 text-xl mt-5">Wallet Debit</h2>
      <div className="flex flex-col">
        <label variant="subtitle2" className="text-left py-1 mt-5">
          User
        </label>
        <select
          className="border p-2 border-black rounded w-1/3"
          onChange={(e) => {
            changeuser(e.target.value);
          }}
        >
          <option className="uppercase font-bold" value="null">
            Select User
          </option>
          {userdata.length !== 0
            ? userdata.map((data, index) => (
                <option
                  key={index}
                  className="uppercase font-bold"
                  value={data.id}
                >
                  {data.name} - {parseFloat(data.balance).toFixed(2)}
                </option>
              ))
            : null}
        </select>
        <label variant="subtitle2" className="text-left py-1 mt-5">
          Amount
        </label>
        <input
          type="text"
          placeholder="Transfer Amount"
          className="border p-2 border-black rounded w-1/3"
          onChange={(e) => {
            setamount(e.target.value);
          }}
        />
        <div className="flex justify-start py-2 mt-5">
          {clicked === true ? (
            <Button
              variant="contained"
              size="small"
              type="submit"
              className=" items-start "
              disabled
            >
              Please wait...
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              type="submit"
              className=" items-start "
              onClick={transferbtn}
            >
              Transfer
            </Button>
          )}
        </div>
      </div>
      <Dropdown />
      <ToastContainer />
    </div>
  );
};

export default WalletDebit;
