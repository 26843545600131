import { Paymentoption } from "../axios";

export const CretePaymentoption = async (data) => {
  var CretePaymentoption = await Paymentoption.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePaymentoption;
};

export const AllPaymentoption = async (data) => {
  var AllPaymentoption = await Paymentoption.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return AllPaymentoption;
};

export const destroyPaymentoption = async (data) => {
  var destroyPaymentoption = await Paymentoption.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyPaymentoption;
};

export const viewByidPaymentoption = async (data) => {
  var viewByidPaymentoption = await Paymentoption.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidPaymentoption;
};

export const updatePaymentoption = async (data) => {
  var updatePaymentoption = await Paymentoption.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatePaymentoption;
};

export const viewbyuserdataPaymant = async (data) => {
  var viewbyuserdataPaymant = await Paymentoption.post(`/viewbyuserdata`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewbyuserdataPaymant;
};
