/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  destroyPaymentoption,
  viewbyuserdataPaymant,
} from "../Api/paymentoption";

const Paymentgateway = () => {
  const [allpaymentmethod, setallpaymentmethod] = useState([]);

  useEffect(() => {
    getalldata();
  }, []);

  const getalldata = async () => {
    var paymentmethod = await viewbyuserdataPaymant({
      id: sessionStorage.getItem("edituser"),
    });
    setallpaymentmethod(paymentmethod);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = allpaymentmethod.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allpaymentmethod.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const deletebtn = async (e) => {
    await destroyPaymentoption({ id: e });
    window.location.reload();
  };

  const editbtn = (e) => {
    sessionStorage.setItem("editpayment", e);
    window.location.replace(`/editpaymentgateway`);
  };

  return (
    <div>
      <div className="flex justify-between py-4">
        <h1 className="text-start font-bold text-xl">Payment Gateway List</h1>
        <Button
          variant="contained"
          size="small"
          color="info"
          onClick={() => window.location.replace("/addpaymentgateway")}
        >
          Add Payment Gateway
        </Button>
      </div>
      <div className="my-8" style={{ height: 700, width: "100%" }}>
        <TableContainer component={Paper} className="border border-black">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-black text-white">
              <TableRow className="border border-black">
                <TableCell align="center">
                  {" "}
                  <text className="font-bold text-white text-center">#</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs text-center">
                    Name
                  </text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs text-center">
                    Percentage
                  </text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs text-center">
                    Edit
                  </text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white text-xs text-center">
                    Remove
                  </text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-2">
              {currentPost.length !== 0
                ? currentPost.map((data, index) => (
                    <TableRow className="border border-black" key={index}>
                      <TableCell className="border border-black" align="center">
                        <p> {index + 1}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="center">
                        <p className="text-xs"> {data.name}</p>
                      </TableCell>
                      <TableCell className="border border-black" align="center">
                        <p className="text-xs"> {data.percentage}</p>
                      </TableCell>

                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="center"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="m-2 items-start "
                          color="primary"
                          onClick={() => {
                            editbtn(data.id);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="center"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="p-2 items-start "
                          color="error"
                          onClick={() => {
                            deletebtn(data.id);
                          }}
                        >
                          remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {pageNumbers.length > 1 ? (
          <div className="row mt-5">
            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={() => setCurrentPage(number)}
                    className="page-link"
                  >
                    {number}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Paymentgateway;
