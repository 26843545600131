/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updatePaymentoption,
  viewByidPaymentoption,
} from "../Api/paymentoption";

const Editpaymentgateway = () => {
  var id = sessionStorage.getItem("editpayment");
  const [userdata, setuserdata] = useState({
    name: "",
    percentage: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [clicked, setclicked] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setuserdata((values) => ({ ...values, [name]: value }));
  };

  const submitbtn = async () => {
    const newerrors = {};
    if (!userdata.name) {
      newerrors.name = "Name Required";
    }
    if (!userdata.percentage) {
      newerrors.percentage = "Percentage Required";
    }

    seterrorlist(newerrors);
    setclicked(false);
    if (Object.keys(newerrors).length === 0) {
      setclicked(true);
      userdata["id"] = sessionStorage.getItem("editpayment");
      var createuser = await updatePaymentoption(userdata);
      if (createuser === "Updated Successfully") {
        toast.success("Payment Gateway Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.replace("/charges");
        }, 2000);
      } else {
        toast.error(createuser.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };

  useEffect(() => {
    getuserdata();
  }, [id]);

  const getuserdata = async () => {
    if (id !== null) {
      var viewuser = await viewByidPaymentoption({ id: id });
      if (viewuser.length !== 0) {
        setuserdata({
          name: viewuser[0].name,
          percentage: viewuser[0].percentage,
        });
      }
    }
  };
  console.log(userdata, "userdata");
  return (
    <div>
      <div className="flex justify-between pt-4">
        <h1 className="text-start font-bold text-xl">Edit Payment Gateway</h1>
      </div>
      <div className="pt-2">
        <Stack spacing={0.6} direction={"column"} className="  ">
          <label className="text-left font-semibold">
            Select Payment Gateway{" "}
          </label>
          <select
            size="medium"
            placeholder="Name"
            name="name"
            className="w-96 border border-gray-600 p-2 rounded"
            onChange={handleChange}
            value={userdata.name}
          >
            <option value=""> --Select--</option>
            <option value="PAYU">PAYU</option>
            <option value="PAYU ED">PAYU ED</option>
            <option value="EASEBUZZ">EASEBUZZ</option>
            <option value="RAZORPAY">RAZORPAY</option>
            <option value="CASHFREE A">CASHFREE A</option>
            <option value="CASHFREE EDU">CASHFREE EDU</option>
            <option value="RAZORPAY OLBUIZ">RAZORPAY OLBUIZ</option>
            <option value="RAZORPAY WALLET">RAZORPAY WALLET</option>
            <option value="CASHFREE CROWPE EDU">CASHFREE CROWPE EDU</option>
            <option value="CASHFREE CROWPE FAST">CASHFREE CROWPE FAST</option>
            <option value="RAZORPAY CROWPE FAST">RAZORPAY CROWPE FAST</option>
          </select>
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.name}</div>
          ) : null}
          <label className="text-left font-semibold">Percentage</label>
          <input
            type="number"
            name="percentage"
            placeholder="Percentage"
            onChange={handleChange}
            defaultValue={userdata.percentage}
            className="w-96 border border-gray-600 p-2 rounded"
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">
              {errorlist.percentage}
            </div>
          ) : null}
        </Stack>
      </div>

      <div className="flex justify-start mt-5 mb-5 w-24">
        <Stack spacing={2}>
          {clicked === true ? (
            <button className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3 w-[10rem]">
              Please Wait...
            </button>
          ) : (
            <button
              className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3 w-[10rem]"
              onClick={submitbtn}
            >
              Update
            </button>
          )}
        </Stack>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Editpaymentgateway;
